
const MailAddress: React.FC<{ type: string; address: string }> = ({
  type,
  address,
}) => {
  return (
    <div className="flex flex-row">
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
      </svg>

      </div>
      <div>
      <span className="text-1xl font-bold tracking-tight text-indigo-900">
        {address}
      </span>
      </div>
    </div>
  );
};


export default MailAddress;