// Assuming you are using React
import React, { useState } from "react";
import axios from "axios";

function StartVerificationButton() {
  const [verificationUrl, setVerificationUrl] = useState("");

  const startVerification = async () => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(
        "/api/stripe/start-verification",
        {},
        config
      );
      setVerificationUrl(response.data.url); // URL to Stripe's hosted verification page
      const popup = window.open(response.data.url, "_blank");
      const checkPopupClosed = setInterval(() => {
        if (popup && popup.closed) {
          console.log("Popup closed");
          clearInterval(checkPopupClosed);
          // Here you can handle the event when the popup is closed
        }
      }, 1000);
    } catch (error) {
      console.error("Error starting verification:", error);
    }
  };

  return (
    <button
      onClick={startVerification}
      className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 ml-auto text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Identity Verification
    </button>
  );
}

export default StartVerificationButton;
