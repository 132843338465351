import { useState } from "react";
import SideBar from "../../experience/SideBar"
import { Link } from "react-router-dom";
import Footer from "../../experience/Footer";
import DemoLegend from "../../experience/demo";

const PaymentError = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>
      <div className="flex flex-col justify-center items-center mt-10 mx-2">
      <DemoLegend />
      <div className={`mx-auto mt-5 md:mt-14 rounded-xl  sm:mt-20 lg:mx-0 lg:flex my-40`}>

        <h1>We had an error processing your payment</h1>
          <p>We are following the operation to find issues.</p> 
          <p>Please try again.</p>
          <Link to="/user_profile" className="text-blue-500">Go back to your profile</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentError;
