import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlockAddress } from "../commons/BlockAddress";
import PoolStake from "./PoolStake";
import { PoolDto } from "../../services/pools/poolTypes";
import Money from "../commons/Money";

const Pool: React.FC<{
  pool: PoolDto;
  poolContract: any;
}> = ({
  pool,
  poolContract,
}) => {
  // const { user } = useContext(UserContext);
  // const { web3, userAddress, fetchUser, fetchPools, currencyContract } =
  // useContext(Web3ProviderContext);


  const increaseAllowance = async (
    poolAddress: string,
    userAddress: string,
    valueToStake: string
  ) => {
    //   await currencyContract.methods.increaseAllowance(poolAddress,valueToStake.toString()).send({from:userAddress}).then((result) => {
    //     console.log("on confirmation ...", result)
    //   }).catch((err) => {
    //     console.log("on error ...", err)
    //     setRendering({...rendering, stake:undefined})
    // });
  };

  const stakeIn = async (poolAddress: string, valueToStake: string) => {
    //     if ([null, undefined].includes(currencyContract) || [null, undefined].includes(poolContract)) {
    //       alert("Operations on Car Defier requires connection to Polygon Mumbai and MetaMask fully enabled")
    //       return false
    //     }
    //     if (valueToStake == 0) {
    //       return false
    //     }
    //     let allowed = await currencyContract.methods.allowance(userAddress, poolAddress).call()
    //     let allow
    //     let weiValueToStake = web3.utils.toWei(valueToStake,'ether')
    //     console.log("staked", allowed, valueToStake, weiValueToStake)
    //     setRendering({...rendering, stake:'allowing'})
    //     if (!web3.utils.fromWei(allowed,'ether') < weiValueToStake) {
    //       allow = increaseAllowance(poolAddress, userAddress, weiValueToStake)
    //     } else {
    //       allow = true
    //     }
    //     if (!allow) {
    //       setRendering({...rendering, stake:undefined})
    //       return false
    //     }
    //     setRendering({...rendering, stake:'staking'})
    //     await poolContract.methods.stakeIn(weiValueToStake).send({from:userAddress}).then((result) => {
    //       fetchUser()
    //       fetchPools()
    //       return true
    // //         socket.emit('stakeincar',JSON.stringify({carAddress:stakeadd,valueToStake:carStake}))
    //     }).catch((err) => {
    // //         renderContext('next_invest')
    //       return false
    //       console.log(err.message)
    //     });
    //     setRendering({...rendering, stake:undefined})
  };

  return (
    <div
      className={`mx-auto mt-5 md:mt-14 rounded-xl md:rounded-2xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex bg-white`}
    >
      <div className="p-4 md:p-8 lg:flex-auto">
        <Link to={"/pool/" + pool.pool} className="flex flex-row mb-3">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900">
            {pool.name}
          </h3>
        </Link>

        {pool.brief}
        <br></br>
        <div className="flex flex-col mt-3">
          <BlockAddress type={"address"} address={pool.pool}></BlockAddress>
          <div className="flex flex-row">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                />
              </svg>
            </div>
            <Link to={"/pool/" + pool.pool} className="flex flex-row">
              <button className="bg-red mx-2 py-0.5 ">View Car Loans</button>
            </Link>
          </div>
        </div>

        <div className="mt-10 flex items-center gap-x-4 text-indigo-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
            />
          </svg>
          <span className="flex-none text-sm font-semibold leading-6">
            Pool Analytics
          </span>
          <div className="h-px flex-auto bg-gray-100" />
        </div>
        <ul
          role="list"
          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
        >
          <li className="flex gap-x-3">
          <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
                  <Money type={"CUSD"} money={pool.staked} tip={"Ever Staked CUSD"} />
              </li>
              <li className="flex gap-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
                  <Money type={"CUSD"} money={pool.interestsCollected} tip={"Interests Collected CUSD"} />
              </li>
              <li className="flex gap-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
                  <Money type={"CUSD"}  money={pool.principalRemaining} tip={"Principal Remaining CUSD"} />
              </li>
              <li className="flex gap-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
                  <Money type={"CUSD"} money={pool.cash} tip={"Available Cash CUSD"} />
              </li>

          {/* {pool.summary.map((summary: string, index:number) => (
              <li key={summary} className="flex gap-x-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24  "
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
                {summary}
              </li>
            ))} */}
        </ul>
      </div>
      <PoolStake
        poolContract={poolContract}
        invested={pool.currentStake}
        poolname={pool.name}
        poolColor={"bg-"+pool.color+"-200 "}
        poolAddress={pool.pool}
        poolSummary={pool.summary}
        stakeIn={stakeIn}
      />
    </div>
  );
};

export default Pool;
