import React, { useState } from 'react';
import { EventsPoolStakeDto } from "../../services/pools/poolTypes";
import {
  BlockAddress,
  BlockCalendar,
  BlockMoneyInLine,
  TXAddress,
} from "../commons/BlockAddress";
import Pagination from '../../components/pagination';

const ITEMS_PER_PAGE = 5;

const PoolStakeEvents = ({
  stakingEvents,
}: {
  stakingEvents: EventsPoolStakeDto[];
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  if (!stakingEvents) {
    return <></>;
  }

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = stakingEvents.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(stakingEvents.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="mx-auto w-full md:max-w-2xl py-4 md:p8 min-h-screen">
      <div className="mx-auto mt-5 w-full rounded-xl md:rounded-2xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col">
        <div className="p-4 md:p-8 lg:flex-auto">
          <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center mb-5">
            Staking Activity
          </h3>

          <div className="p-4 md:p-8 flex flex-col">
            {currentItems.map((event) => {
              return (
                <div
                  key={event.hash}
                  className="flex flex-col mb-5"
                >
                  <BlockCalendar tip={""} date={event.createdAt} />
                  <BlockAddress type={"address"} address={event.user} />
                  <TXAddress type={"tx"} tx={event.hash} />
                  <BlockMoneyInLine
                    type={"wei"}
                    money={event.amount}
                    tip={""}
                  />
                  <hr className="mt-5" />
                </div>
              );
            })}
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PoolStakeEvents;