const DemoLegend = () => {


    return (
        <div className="col-xs-12 text-center bg-orange-200 p-4 my-2 rounded-xl">
          <p><b>This is a Demo Website.</b></p>
          <p>Please note that this website is currently under development and is still being tested. No actual transactions or investments can be made at this time. Any information displayed is for demonstration purposes only. Thank you for your understanding.</p>
        </div>
    )
}

export default DemoLegend