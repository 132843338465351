// src/components/Main.tsx

import React, { ReducerState, useState } from "react";
import SideBar from "./experience/SideBar";
import PoolPanel from "./pool/PoolPanel";
import Footer from "./experience/Footer";
import B2BPreview from "./b2b/B2BPreview";
import { useSelector } from "react-redux";
import { StoreState } from "../services/app/store";
import DemoLegend from "./experience/demo";

function Main() {
  const pools = useSelector((state: StoreState) => state.pools);

  const [open, setOpen] =   useState<boolean>(false);

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
    
      </header>
      <div className="relative isolate">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
        <div className="flex flex-col justify-start items-center mt-10 mx-2">
        <DemoLegend />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-20">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Car Lending on DeFi roads
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Stake on our Car Lending Pools and get up to 15% APR.
            </p>
          </div>
        </div>

        {Object.keys(pools).map((pool) => {
          return (
            <div className="my-100 mx-auto max-w-5xl">
              <PoolPanel pool={pools[pool]} />
            </div>
          );
        })}

        <div className="my-10">
          <div className="relative overflow-hidden bg-white">
            <B2BPreview />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
