const Terms = () => {

    return (
        <div className="max-h-[70vh] overflow-y-auto">

        <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
            <p className="mb-2">
            Welcome to Car Defier. These Terms and Conditions ("Terms") govern your access to and use of the Car Defier platform, which facilitates investments in U.S.-based car loans. By accessing or using Car Defier, you agree to comply with these Terms. Please read them carefully before proceeding. If you do not agree to these Terms, you must refrain from using the platform.
            </p>
            <p>
            Car Defier reserves the right to update or modify these Terms at any time. Any changes will be communicated via the platform or email, and continued use of Car Defier constitutes acceptance of the revised Terms.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">2. Eligibility</h2>
            <p className="mb-2">To use Car Defier, you must meet the following criteria:</p>
            <ul className="list-disc list-inside mb-2">
            <li>You are not a resident or citizen of the United States.</li>
            <li>You have the legal capacity to enter into binding contracts under applicable law.</li>
            <li>You understand the risks involved in investing in car loans and accept that Car Defier does not provide financial advice or guarantees.</li>
            </ul>
            <p>
            By using the platform, you represent and warrant that you meet these eligibility requirements.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">3. Investment Process</h2>
            <p className="mb-2">
            Car Defier enables investors to participate in car loans originating in the United States. Investors contribute funds which Car Defier then allocates to various loan pools. The investment pools are categorized as "Platinum", "Gold", or "Silver", each with distinct features and applicable fees (see Section 6). Car Defier estimates an annual return of 12%, though this figure is not guaranteed and is subject to market conditions.
            </p>
            <p>
            Investors will accrue interest on a daily basis. Both capital and accrued interest are available for withdrawal at any time, though fees may apply based on the selected pool (see Section 6).
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">4. Investor Acknowledgements and Risks</h2>
            <p className="mb-2">
            By investing through Car Defier, you acknowledge and accept the following:
            </p>
            <ul className="list-disc list-inside mb-2">
            <li><strong>Risk of Loss:</strong> Investing in car loans carries inherent risks, including the potential for partial or total loss of your investment. Car Defier does not guarantee any specific rate of return or preservation of capital.</li>
            <li><strong>No Financial Advice:</strong> Car Defier does not provide investment, legal, or tax advice. You are solely responsible for your investment decisions and should consult with a professional advisor if necessary.</li>
            <li><strong>Acceptance of Investment Terms:</strong> By proceeding with an investment, you acknowledge that you have reviewed and understand the risks associated with the investment and agree to the terms of the specific loan pool you are participating in.</li>
            </ul>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">5. Car Defier's Role</h2>
            <p className="mb-2">
            Car Defier acts solely as an intermediary that facilitates the investment process by connecting investors with car loans. Car Defier does not originate, manage, or underwrite the loans. It does not assume any responsibility or liability for the performance of the loans, nor does it guarantee the repayment of any principal or the success of the loans.
            </p>
            <p>
            The investor understands that Car Defier's role is limited to the execution of the transactions and agrees that Car Defier is not liable for any direct or indirect losses that may result from the performance of the loans.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">6. Fees and Withdrawal Policy</h2>
            <p className="mb-2">
            Investors may withdraw their capital and accrued interest at any time subject to the fee structure associated with the selected pool:
            </p>
            <ul className="list-disc list-inside mb-2">
            <li><strong>Platinum Pool:</strong> [__]</li>
            <li><strong>Gold Pool:</strong> [__]</li>
            <li><strong>Silver Pool:</strong> [__]</li>
            </ul>
            <p>
            Fees may vary based on the time of withdrawal and the pool in which the funds are invested. Car Defier reserves the right to modify the fee structure at its discretion. Any changes to fees will be communicated to investors in advance.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">7. Payment Processing</h2>
            <p className="mb-2">
            Car Defier uses a third-party payment processor ("Payment Processor") to handle all transactions on the platform. The Payment Processor is responsible for processing payments, including investment contributions and withdrawals. Investors acknowledge that Car Defier does not retain any payment details (such as credit card or bank information) submitted through the Payment Processor.
            </p>
            <p>
            Investors understand and agree that Car Defier is not responsible for any errors, delays, or issues that arise from the Payment Processor's handling of transactions. The terms and conditions of the Payment Processor will apply to all transactions, and investors are encouraged to review them.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">8. Privacy and Data Protection</h2>
            <p className="mb-2">
            Car Defier is committed to protecting your personal information. The platform collects and retains the following personal data:
            </p>
            <ul className="list-disc list-inside mb-2">
            <li>Full name</li>
            <li>Identification number</li>
            <li>Date of birth</li>
            <li>Residential address</li>
            </ul>
            <p>
            Car Defier does not collect or store any payment information, which is processed exclusively by the Payment Processor. Investors should refer to the privacy policy of the Payment Processor for details on how their payment data is handled.
            </p>
            <p>
            Car Defier will not share personal data with third parties except as required by law or as necessary to provide services to investors.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">9. Jurisdiction and Governing Law</h2>
            <p className="mb-2">
            These Terms and Conditions are governed by the laws of the State of Delaware, without regard to its conflict of law principles. Any disputes arising from or related to your use of Car Defier shall be resolved exclusively in the courts located in Delaware.
            </p>
            <p>
            By agreeing to these Terms, you consent to the personal jurisdiction of Delaware courts and waive any objections to jurisdiction or venue.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">10. Limitation of Liability</h2>
            <p className="mb-2">
            Car Defier, its affiliates, officers, employees, and agents shall not be liable for any indirect, incidental, consequential, or punitive damages arising from or related to your use of the platform, including but not limited to losses related to the performance of car loans.
            </p>
            <p>
            In no event shall Car Defier's liability exceed the total amount of fees you have paid to Car Defier in the twelve (12) months preceding the event giving rise to the claim. This limitation applies to all causes of action, whether in contract, tort, or otherwise.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">11. Termination of Use</h2>
            <p className="mb-2">
            Car Defier reserves the right to suspend or terminate your access to the platform at any time for any reason, including but not limited to a violation of these Terms. In the event of termination, your right to use Car Defier will immediately cease, and any outstanding investments will be managed according to the terms of the applicable loan pools.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">12. Amendments to Terms</h2>
            <p className="mb-2">
            Car Defier may modify or update these Terms from time to time to reflect changes in the platform, legal requirements, or business practices. Any amendments will be effective upon posting on the Car Defier website or via email notification. Your continued use of the platform after any changes to the Terms constitutes your acceptance of the revised Terms.
            </p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-2">13. Contact Information</h2>
            <p>
            If you have any questions, concerns, or need assistance regarding these Terms and Conditions, please contact us at [__].
            </p>
        </section>
        </div>
        </div>

    )

}

export default Terms;