// PaymentsHistory.tsx
import React, { useState } from 'react';
import { BlockCalendar, TXAddress, BlockMoneyInLine } from "../commons/BlockAddress";
import BlockData from "../commons/BlockData";
import { CarLoanEvent } from "../../services/cars/carTypes";
import ScatterPlot from "./Scatter";
import Pagination from '../../components/pagination';

const ITEMS_PER_PAGE = 5;

const PaymentsHistory: React.FC<{ carLoanContractsEvents?: CarLoanEvent[] }> = ({ carLoanContractsEvents }) => {
  const [currentPage, setCurrentPage] = useState(1);

  if (!carLoanContractsEvents) {
    return <></>;
  }

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = carLoanContractsEvents.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(carLoanContractsEvents.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="mx-auto max-w-2xl py-4">
      <div className="mx-auto mt-5 max-w-2xl rounded-xl md:rounded-2xl sm:mt-20 lg:mx-0 lg:flex w-full md:max-w-2xl flex flex-col p-4 md:p-8 ">
        <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center mb-5">
          Payments history
        </h3>

        <ScatterPlot data={carLoanContractsEvents} />

        <div className="flex flex-col w-full">
          {currentItems.map((event: CarLoanEvent) => (
            <div key={event.hash} className="flex flex-col">
              <div>
                <BlockCalendar tip={""} date={event.createdAt} />
                <TXAddress type={"tx"} tx={event.hash} />
                <BlockMoneyInLine type={"wei"} money={event.amortization} tip={" Principal"} />
                <BlockMoneyInLine type={"wei"} money={event.interest} tip={" Interest"} />
              </div>
              <div className="mt-5">
                <hr />
              </div>
            </div>
          ))}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default PaymentsHistory;