// src/context/AuthContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { clearUser, updateUser, UserState } from "../services/users/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../services/app/store";
import { userProfileRest } from "../services/auth/auth-service";
import { getValidToken } from "../utils/tokenUtils";
import api from "../utils/axiosInstance";
import { useSocket } from './socket-context';
import { streamHooks } from "../utils/streamHooks";


interface AuthContextType {
  userState: UserState;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string) => Promise<void>;
  checkVerificationStatus: () => Promise<string>; // Ensure this is added
  refreshUserData: () => Promise<void>; // Adding this method
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { socket } = useSocket(); // Access the socket from context 
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);

  const checklogged = async () => {
    const token = await getValidToken();
    if (token) {
      try {
        const user = await userProfileRest();
        dispatch(updateUser({ user }));

      if (socket) {
        socket.emit(streamHooks.logged, { token }); // Send a message to the server
      }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(clearUser());
      }
    } else {
      dispatch(clearUser());
    }
    setLoading(false);
  };

  useEffect(() => {
    checklogged();
  }, []);

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
        await checklogged();
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoading(false);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    dispatch(clearUser());
    window.location.href = "/login";
  };

  const register = async (email: string, password: string) => {
    try {
      const response = await api.post('/auth/register', { email, password });
      dispatch(updateUser(response.data));
    } catch (error: any) {
      console.log("Error", error.response?.data?.message || error.message);
      throw new Error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const checkVerificationStatus = async (): Promise<string> => {
    // Implementation
    return "Status";
  };

  const refreshUserData = async () => {
    // Fetch user data from the backend and update the context
    try {
      let token = localStorage.getItem("token");
      if (!token) return;
      const response = await fetch(`/api/users/profile/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Could not fetch user data");
      }
      const data = await response.json();
      dispatch(updateUser(data));
    } catch (error) {
      console.error("Failed to refresh user data:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userState,
        loading,
        register,
        login,
        logout,
        checkVerificationStatus,
        refreshUserData, // Add the refreshUserData property
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
