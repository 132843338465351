import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { streamHooks } from '../utils/streamHooks';
import { userProfileRest } from '../services/auth/auth-service';
import { updateUser } from '../services/users/userSlice';
import { useDispatch } from 'react-redux';

const SOCKET_SERVER_URL = '' // 'http://localhost:5000'; // Your server URL

// Define the shape of your context
interface SocketContextType {
  socket: Socket | null;
}

// Create a context with the type defined
const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Custom hook to use the socket context
export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

// Define the props type for the provider
interface SocketProviderProps {
  children: ReactNode;
}

// Socket provider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const socketConnection = io(SOCKET_SERVER_URL);
    setSocket(socketConnection);

    // Listen for the 'payment' event
    socketConnection.on(streamHooks.stripePayment, (data) => {

      if (data.status == streamHooks.blockchainConfirmed) {
        console.log('Blockchain confirmed', data);
        toast("Transaction was confirmed on blockchain"); // Show a toast notification
        return;
      }


      if (data.status == streamHooks.stripePaymentConfirmed) {
        console.log('Blockchain confirmed', data);
        toast("Transaction was confirmed by Stripe"); // Show a toast notification
        return;
      }

      console.log('Stripe payment news', data);
      toast("Stripe Payment News"); // Show a toast notification
      toast(data.status); // Show a toast notification
      
    });


    // Listen for the 'blockchainUpdate' event
    socketConnection.on(streamHooks.poolUpdate, (data) => {
      console.log('Blockchain update received:', data);
      toast("Blockchain update received"); // Show a toast notification
    });

    socketConnection.on(streamHooks.carUpdate, (data) => {
      console.log('Car Loan Updated Received:', data);
      toast("Car Loan Updated"); // Show a toast notification
    });

    socketConnection.on(streamHooks.identityVerified, async() => {
      console.log("Identity verified");
      toast("Identity validation done"); // Show a toast notification
      const user = await userProfileRest();
      dispatch(updateUser({ user }));
    });
    return () => {
      socketConnection.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
      <ToastContainer />
    </SocketContext.Provider>
  );
};
