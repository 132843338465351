import { useNavigate } from 'react-router-dom';

const Stake: React.FC<{
  }> = ({  }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => { 
      navigate('/login');
    }


    return (
            <div className={`my-4 lg:mt-0 lg:w-full lg:flex-shrink-0 text-left`}>
              <div className="rounded-2xl bg-gray-50 py-6 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center">
                <div className="mx-auto px-8">
                  <div>
                  <h3 className="text-base font-semibold text-gray-600">
                    Invest now and get the best RISK-APR
                  </h3>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1"></div>

                    {/*<div className="absolute inset-y-0 right-0 flex items-center pr-3"> {currencySymbol} </div>*/}
                  </div>

                  <button
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    // onClick={() => {
                    //   stakeIn(poolAddress, valueToStake);
                    // }}
                    onClick={handleButtonClick}
                  >
                    Stake now!
                  </button>

                    <p>
                      Make your first invesment
                    </p>
              
                
                  </div>
                </div>
              </div>
            </div>
    )
}

export default Stake;