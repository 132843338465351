// src/App.tsx

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/auth-context";
import AppRoutes from "./routes/app-routes";
import Modal from "./pages/modals/Modal";
import { QueryClient, QueryClientProvider } from 'react-query';
import {SocketProvider} from "./context/socket-context";


function App() {
  const queryClient = new QueryClient();

  return (
    <SocketProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
            <Router>
              <Modal />
              <AppRoutes />
            </Router>
        </QueryClientProvider>
      </AuthProvider>
    </SocketProvider>
  );
}

export default App;