import React from "react"
import StartVerificationButton from "../../components/elements/StartVerificationButton";
import UserWallets from "./UserWallet";
import MailAddress from "../commons/MailAddress";

interface UserStateCardProps {
    userState: any;
    logout: any;
}

const UserStateCard : React.FC<UserStateCardProps> = ({userState, logout}) => {


    return (
        <div className="rounded-2xl p-4 text-left m bg-gray-50  ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center mb-8">

            <MailAddress address={userState.user.email} type={"userEmail"} />
            {userState.user.status === "unverified" && (
            <div className="flex flex-col my-4">
              <span className="inline-flex items-center rounded-md bg-red-50 px-3 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 my-1 w-full">
                Your account not verified
              </span>
               <StartVerificationButton />
            </div>  
            )}

            <hr></hr>
            <h4 className="text-1xl font-bold tracking-tight text-indigo-900 mt-4">
                Your Wallet
            </h4>
            <UserWallets userId={userState.user.id} />

            <hr></hr>

            <div>
            <button
              onClick={logout}
              className="mt-10 ml-auto flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
              >
              Log Out
            </button>
            </div>
          </div>
    )
}

export default UserStateCard