import Reac from "react";
import Web3 from "web3";

const MetaMaskAddress: React.FC<{ action: () => void; address: string }> = ({
  action,
  address,
}) => {
  return (
    <div className="flex flex-row">
      <div>
        <img
          src="https://cardefier.io/img/cdn/metamask-logo.svg"
          className="w-6"
          alt="metamask-logo"
        />
      </div>
      <button
        onClick={() => action()}
        className="address-container bg-gray-100 text-gray-800 text-xs font-medium mx-2 py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 "
      >
        {address}
      </button>
    </div>
  );
};

const PlainBlockAddress: React.FC<{
  type: string;
  address: string;
}> = ({ type, address }) => {
  return (
    <div className="address-container bg-gray-100 text-gray-800 text-xs font-medium mx-2 py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 ">
      <a
        href={"https://oklink.com/es-la/amoy/" + type + "/" + address}
        target="_blank"
        rel="noreferrer"
      >
        <span className="hidden sm:hidden md:block">
          {address}
        </span>
        <span className="block sm:block md:hidden">
         ... {address?.length > 20 ? address.substring(address.length-20,address.length): address }
        </span>
      </a>
    </div>
  );
};

const BlockAddress: React.FC<{ type: string; address: string }> = ({
  type,
  address,
}) => {
  return (
    <div className="flex flex-row">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
          />
        </svg>
      </div>
      <PlainBlockAddress type={type} address={address}></PlainBlockAddress>
    </div>
  );
};

const BlockCalendar = ({ tip, date }: { tip: string; date: string }) => {
  return (
    <div className="flex flex-row mt-1">
      <div className="mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="wr-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
          />
        </svg>
      </div>

      <div className="flex flex-col">
        <div>{tip}</div>
        <div className="address-container bg-gray-100 text-gray-800 text-xs font-medium py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 ">
          {date}
        </div>
      </div>
    </div>
  );
};

const Progress = ({ tip, date }: { tip: string; date: string }) => {
  return (
    <div className="flex flex-row mt-1">
      <div className=" mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
          />
        </svg>
      </div>
      <div className="flex flex-col">
        <div>{tip}</div>
        <div className="address-container bg-gray-100 text-gray-800 text-xs font-medium mx-2 py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 ">
          {date}
        </div>
      </div>
    </div>
  );
};

const AssetName = ({ name }: { name: string }) => {
  return (
    <div className="flex flex-row mt-1">
      <div className=" mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"
          />
        </svg>
      </div>
      <div>{name}</div>
    </div>
  );
};

const TXAddress: React.FC<{ tx: string; type: string }> = ({ tx, type }) => {
  return (
    <div className="flex flex-row mt-1">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
          />
        </svg>
      </div>
      <PlainBlockAddress type={"tx"} address={tx}></PlainBlockAddress>
    </div>
  );
};


const BlockMoneyInLine: React.FC<{
  money: string;
  tip: string;
  type: string;
  ethers?: boolean;
}> = ({ money, tip, type, ethers=true }) => {
  const web3 = new Web3();

  const formatMoney = (money: string, ethers: boolean): string => {
    let amount;
    if (ethers) {
      try {
        amount = parseFloat(Web3.utils.fromWei(money, 'ether'));
      } catch (error) {
        console.error("Invalid value for Web3.utils.fromWei:", money);
        amount = parseFloat(money);
      }
    } else {
      amount = parseFloat(money);
    }
    return amount.toFixed(4); // Change to 6 if needed
  };

  const formattedMoney = formatMoney(money, ethers);


  return (
    <div className="flex flex-row mt-1">
      <div className="mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>
      <div className="flex flex-row">
        <div className="address-container bg-gray-100 text-gray-800 text-xs font-medium py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 ">
          {tip}
        </div>
        <div className="address-container bg-gray-100 text-gray-800 text-xs font-medium py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 ">
          {formattedMoney}
        </div>
      </div>
    </div>
  );
};

export {
  AssetName,
  BlockAddress,
  MetaMaskAddress,
  PlainBlockAddress,
  TXAddress,
  BlockCalendar,
  BlockMoneyInLine,
  Progress,
};
