// CollectionsHistory.tsx
import React, { useState } from 'react';
import { BlockCalendar, TXAddress, BlockMoneyInLine } from "../commons/BlockAddress";
import BlockData from "../commons/BlockData";
import { CarLoanCapitalize } from "../../services/cars/carTypes";
import Pagination from '../../components/pagination';

const ITEMS_PER_PAGE = 5;

const CollectionsHistory: React.FC<{ carLoanContractsCapitalize?: CarLoanCapitalize[] }> = ({ carLoanContractsCapitalize }) => {
  const [currentPage, setCurrentPage] = useState(1);

  if (!carLoanContractsCapitalize) {
    return <></>;
  }

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = carLoanContractsCapitalize.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(carLoanContractsCapitalize.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="mx-auto max-w-2xl py-4">
      <div className="mx-auto mt-5 max-w-2xl rounded-xl md:rounded-2xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col">
        <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center mb-5">
          Collections history
        </h3>

        <div className="p-4 md:p-8 flex flex-col">
          {currentItems.map((event: CarLoanCapitalize) => (
            <div key={event.hash} className="flex flex-col">
              <div>
                <BlockCalendar tip={""} date={event.createdAt} />
                {/* <BlockData tip={""} data={event.number} /> */}
                <TXAddress type={"tx"} tx={event.hash} />
                <BlockData data={event.periods} tip={" Periods"} />
                <BlockMoneyInLine type={"wei"} money={event.balanceRemaining} tip={" Principal"} />
                <BlockMoneyInLine type={"wei"} money={event.interest} tip={" Interest"} />
              </div>
              <div className="mt-5">
                <hr />
              </div>
            </div>
          ))}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default CollectionsHistory;