import { CarLoanContract, CarLoanContractFull } from "../../services/cars/carTypes";
import {
    BlockAddress,
    BlockMoneyInLine,
    TXAddress,
    BlockCalendar,
    Progress,
  } from "../commons/BlockAddress";
  import { Link, useParams } from "react-router-dom";
import BlockMoney from "../commons/BlockMoney";
import BlockData from "../commons/BlockData";
import BlockRate from "../commons/BlockRate";
import BlockBoolean from "../commons/BlockBoolean";

const CarLoanBlockchain : React.FC<{carLoanContract: CarLoanContractFull}>=  ({carLoanContract}) => {
    return (
        <div className="px-4">
        <div className="p-4 lg:flex-auto">
          
        <BlockAddress
            type={"type"}
            address={carLoanContract.carloan + " Loan Address"}  
        />
        <BlockAddress
          type={"type"}
          address={carLoanContract.pool + " Pool Address"}  
        />
          <BlockAddress
            type={"type"}
            address={carLoanContract.borrower + " Borrower Address"}  
          />
          <BlockAddress
            type={"type"}
            address={carLoanContract.collector + " << collector"}  
          />
          {/* <BlockMoney
            type={"type"}
            money={carLoanContract.cash}  
            tip={"Cash:"}
          /> */}
          <BlockMoney
            type={"address"}
            money={carLoanContract?.principalRemaining}
            tip={"Principal Remaining:"}
          />
          
          <BlockMoney
            type={"address"}
            money={carLoanContract?.balanceRemaining}
            tip={"Remaining Balance:"}
          />

          <BlockMoney
            type={"interest"}
            money={carLoanContract?.interestPaid}
            tip={"Interest Paid:"}
          />

          <BlockMoney
            type={"address"}
            money={carLoanContract?.payment}
            tip={"Daily Payment:"}
          />

          <BlockCalendar
            date={carLoanContract?.initDate}
            tip={"Init date:"}
          />

          <BlockData
            data={carLoanContract?.paymentsMade}
            tip={"paymentsMade:"}
          />

          <BlockData
            data={carLoanContract?.payed}
            tip={"payed:"}
          />
          <BlockRate
            data={carLoanContract?.rateNumerator}
            tip={"Daily rate:"}
          />
          <BlockRate
            data={carLoanContract?.rateNumerator}
            tip={"Monthly rate:"}
            base={30}
          />
          <BlockRate
            data={carLoanContract?.rateNumerator}
            tip={"Yearly rate:"}
            base={365}
          />
          {carLoanContract.status && 
          <BlockBoolean
            data={carLoanContract?.status}
            tip={"status:"}
          />}

         
        </div>
      </div>
    )
}

export default CarLoanBlockchain