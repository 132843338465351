import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import SideBar from "../experience/SideBar";
import { CarLoanState, CarLoanEvent, CarLoanCapitalize, CarLoanContract, CarLoan, Car } from "../../services/cars/carTypes";
import Footer from "../experience/Footer";
import {
  BlockMoneyInLine,
  TXAddress,
  BlockCalendar,
} from "../commons/BlockAddress";
import BlockData from "../commons/BlockData";
import { Link, useParams } from "react-router-dom";
import CarLoanBlockchain from "./CarLoanBlockchain";
import CarLoanDetails from "./CarLoanDetails";
import CarDetails from "./CarDetails";

import { getCarLoanContract, getCarLoanContractCollection, getCarLoanContractCapitalize } from "../../services/cars/carsRest";
import { useQuery } from 'react-query';
import Stake from "../stake/stake";
import ScatterPlot from "./Scatter";
import CustomProductDisplay from "../stripecheckout/CustomPrices";
import { useAuth } from "../../context/auth-context";
import DemoLegend from "../experience/demo";
import CollectionsHistory from "./CollectionHistory";
import PaymentsHistory from "./PaymentHistory";

const CarLoanView = () => {

  const { userState, refreshUserData } = useAuth(); // useContext to access auth context
  
  const carLoanAddress = useParams().carLoanAddress;

  const { data, isLoading, error } = useQuery(
    'getCarLoanContract',
    () => getCarLoanContract(carLoanAddress!),
    {
      // Only execute the query if carLoanAddress is not undefined
      enabled: !!carLoanAddress,
    }
  );

  const { data: carLoanContractsEvents, isLoading: isLoadingEvents, error: errorEvents } = useQuery(
    'getCarLoanContractCollection',
    () => getCarLoanContractCollection(carLoanAddress!),
    {
      // Only execute the query if carLoanAddress is not undefined
      enabled: !!carLoanAddress,
    }
  );


  const { data: carLoanContractsCapitalize, isLoading: isLoadingCapitalize, error: errorCapitalize } = useQuery(
    'getCarLoanContractCapitalize',
    () => getCarLoanContractCapitalize(carLoanAddress!),
    {
      // Only execute the query if carLoanAddress is not undefined
      enabled: !!carLoanAddress,
    }
  );


  const [open, setOpen] = useState(false);

  const [carLoanEvents, setCarLoanEvents] = useState<CarLoanEvent[]>([]);

  if (isLoading) (<div>is loading...</div>)

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>

      <div className="flex flex-col justify-center items-center mt-10 mx-2">
        <DemoLegend />
      

        <div className={`mx-auto mt-5 md:mt-14 rounded-xl sm:mt-20 lg:flex w-full md:max-w-2xl`}>
          <div className="lg:flex-auto w-full">
          {data?.carLoanContract.pool &&
            <CustomProductDisplay
              logged={userState?.user?.id ? true:false}
              poolAddress={data.carLoanContract.pool}
              userId={userState?.user?.id}
              />


              
              }


        

            
              {data !== undefined &&
                <div
                className={`mx-auto max-w-2xl rounded-xl md:rounded-2xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col bg-white`}
                >

                <div className="ml-auto text-right">
                    <Link
                      to={"/pool/" + data.carLoanContract?.pool}
                      className="ml-auto"
                    >
                      <button className="mt-4 block w-full bg-slate-300 px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300">
                        Back to Pool
                      </button>
                    </Link>
                  </div>


                  <CarDetails car={data.carLoanContract.car} />

                  <CarLoanBlockchain carLoanContract={data.carLoanContract} />


                  <CarLoanDetails carLoan={data.carLoanContract.carLoan} />

                </div>
              }


          <div className="mx-auto max-w-2xl py-4">
            <PaymentsHistory carLoanContractsEvents={carLoanContractsEvents} />
            <CollectionsHistory carLoanContractsCapitalize={carLoanContractsCapitalize} />
          </div>

          </div>
        </div>
        <Footer />
      </div>
    </div>
  );

   
};

export default CarLoanView;
